<template>
    <zw-sidebar @shown="shown" :title="$t('offering.title.prepare')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-row v-if="form.id && form.type == 'rechnung'">
                    <b-col cols="4">
                        <zw-select-group v-model="form.invoice_type"
                                         name="invoice_type"
                                         :options="invoiceTypeOptions"
                                         :label-prefix="labelPrefix"
                                         :readonly="disableInvoiceType"
                        ></zw-select-group>
                        <b-form-checkbox v-if="form.invoice_type=='partial'"
                                         v-model="form.invoice_positions_percent"
                        >{{ $t(labelPrefix + 'invoice_positions_percent') }}
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="4" v-if="form.invoice_type=='procent'">
                        <zw-input-group v-model="form.percent"
                                        name="percent"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="8" v-if="form.invoice_type=='partial'">
                        <b-form-checkbox-group
                            v-model="form.positions"
                        >
                            <b-table-simple bordered>
                                <tbody>
                                <b-tr v-for="(position,index) in positionsTree"
                                      :key="index"
                                      :style="position.info.type=='category' ? 'background-color:#DFFFDF':''"
                                >
                                    <b-td>
                                        <b-form-checkbox v-if="position.info.type!='category'"
                                                         :value="position.id"
                                                         :disabled="disabled_positions.includes(position.id)"
                                        ></b-form-checkbox>
                                    </b-td>
                                    <b-td>{{ position.info.number }}</b-td>
                                    <b-td>{{ position.info.name }}</b-td>
                                    <b-td v-if="form.invoice_positions_percent">
                                        <zw-input-group v-model="form.positions_percent[position.id]"
                                                        v-if="position.info.type!='category'"
                                                        name="percent"
                                                        disable-label
                                                        :label-prefix="labelPrefix"
                                                        :disabled="disabled_positions.includes(position.id)"
                                        ></zw-input-group>
                                    </b-td>
                                </b-tr>
                                </tbody>
                            </b-table-simple>
                        </b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4" v-if="form.id && form.type == 'rechnung'">
                        <zw-new-date-group v-model="form.invoice_date"
                                           name="invoice_date"
                                           :label-prefix="labelPrefix"
                                           format="DD.MM.YYYY"
                                           validate="required"
                        ></zw-new-date-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.job_number"
                                        name="job_number"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.order_number"
                                        name="order_number"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="4">
                        <zw-select-group v-model="form.address_type"
                                         name="address_type"
                                         :options="addressTypeOptions"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-select-group v-model="form.formality"
                                         :options="getFormality()"
                                         name="formality"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4" v-if="formalityFormat">
                        <b-form-group :label="labelPrefix+'formality_format'">
                            <strong>{{ formalityFormat }}</strong>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-form-group :label="$t(labelPrefix+'header')">
                            <b-form-select v-model="header_id"
                                           :options="headerOptions"
                                           class="mb-3"
                                           @change="headerSelected"
                            ></b-form-select>

                            <zw-ckeditor v-model="form.header"></zw-ckeditor>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t(labelPrefix+'footer')">
                            <b-form-select v-model="footer_id"
                                           :options="footerOptions"
                                           class="mb-3"
                                           @change="footerSelected"
                            ></b-form-select>

                            <zw-ckeditor v-model="form.footer"></zw-ckeditor>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="6">
                    </b-col>
                    <b-col sm="12" class="text-sm-right text-xs-center">
                        <b-button block
                                  variant="primary"
                                  @click="onSubmit()"
                                  class="mr-5"
                        >{{ $t('offering.prepare.button.plain_paper') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "@/components/ZwNewDateGroup";

export default {
    name: 'DocumentPrepareModal',
    components: {ZwNewDateGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            header_id: null,
            footer_id: null,
            form: {
                id: null,
                ids: null,
                type: null,
                header: null,
                footer: null,
                preview: null,
                invoice_date: null,
                address_type: 'billing',
                invoice_type: 'procent',
                formality: 'none',
                positions: [],
                invoice_positions_percent: false,
                positions_percent: {},
            },
            disableInvoiceType: false,
            positionsTree: [],
            disabled_positions: [],
            labelPrefix: 'offering.prepare.label.',
            headerOptions: [],
            footerOptions: [],
            addressTypeOptions: []
        }
    },
    mounted() {
        this.addressTypeOptions = [
            {value: 'billing', text: 'Billing address'},
            {value: 'shipping', text: 'Shipping address'},
        ]

        this.headerOptions.push({value: null, text: 'Empty'})
        this.footerOptions.push({value: null, text: 'Empty'})
    },
    methods: {
        ...mapGetters('CommonData', ['getFormality', 'getInvoiceTypes']),
        ...mapGetters('Templates', ['getFooters', 'getHeaders']),
        ...mapGetters('Invoice', ['getInvoicePrepare']),
        shown() {
            this.form.type = this.payload.type
            this.form.preview = this.payload.preview || false
            const templates = this.$store.dispatch('Templates/fetchTemplates', this.form.type)

            if (this.payload.id && this.form.type != 'gutschrift') {
                this.form.id = this.payload.id

                const invoicePrepare = this.$store.dispatch('Invoice/fetchInvoicePrepare', {
                    id: this.form.id,
                    type: this.form.type
                })

                Promise.all([templates, invoicePrepare])
                    .then(() => {
                        this.form.percent = this.getInvoicePrepare().percent
                        this.positionsTree = this.getInvoicePrepare().tree
                        this.form.positions = this.getInvoicePrepare().invoiced_positions
                        this.disabled_positions = this.getInvoicePrepare().invoiced_positions
                        this.form.invoice_date = this.getInvoicePrepare().invoice_date

                        if (this.disabled_positions.length) {
                            this.disableInvoiceType = true;
                            this.form.invoice_type = 'partial';
                        }
                        if (this.form.percent < 100) {
                            this.disableInvoiceType = true;
                            this.form.invoice_type = 'procent';
                        }

                        this.prepareTemplates()
                        if (this.getInvoicePrepare().header_footer) {
                            const templateData = this.getInvoicePrepare().header_footer
                            if (templateData.header_id) {
                                this.form.header_id = templateData.header_id
                                this.form.header = templateData.header
                            }

                            if (templateData.footer_id) {
                                this.form.footer_id = templateData.footer_id
                                this.form.footer = templateData.footer
                            }

                            this.form.address_type = templateData.address_type
                            this.form.formality = templateData.formality
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                if (this.form.type == 'gutschrift') {
                    this.form.id = this.payload.id
                    this.form.incoming_invoice_id = this.payload.incoming_invoice_id
                }
                this.form.ids = this.payload.ids

                Promise.all([templates])
                    .then(() => {
                        this.prepareTemplates()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        prepareTemplates() {
            this.getHeaders().forEach(header => {
                this.headerOptions.push({value: header.id, text: header.titel})
                if (header.is_default) {
                    this.header_id = header.id
                    this.$set(this.form, 'header', header.header)
                }
            })

            this.getFooters().forEach(footer => {
                this.footerOptions.push({value: footer.id, text: footer.titel})
                if (footer.is_default) {
                    this.footer_id = footer.id
                    this.$set(this.form, 'footer', footer.footer)
                }
            })
        },
        headerSelected(value) {
            let header = this.getHeaders().find(header => header.id == value) ?? null

            this.form.header = header ? header.header : ''
        },
        footerSelected(value) {
            let footer = this.getFooters().find(footer => footer.id == value) ?? null

            this.form.footer = footer ? footer.footer : ''
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Invoice/generateInvoice', this.form)
                        .then((response) => {
                            if (this.form.preview) {
                                response.open_file = true
                            }
                            response.showUrl = true;
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
    computed: {
        formalityFormat: function () {
            if (this.form.formality == 'foermlich') {
                return 'Sehr geehrte Nachname'
            } else if (this.form.formality == 'persoenlich') {
                return 'Sehr geehrte Vorname Nachname'
            }

            return ''
        },
        invoiceTypeOptions: function () {
            let options = []
            this.getInvoiceTypes().forEach(key => {
                options.push({
                    value: key,
                    text: this.$t('invoices.type.' + key),
                })
            })

            return options
        },
    },
}
</script>